<template>
  <header id="header" class="ly_header">
      <div class="bl_header_inner ly_header_inner">
          <router-link to="/">
            <h1>
                <span class="bl_header_inner_icon"></span>
                <div class="bl_header_inner_title ly_header_inner_title">
                    <span>温度交換効率の補正係数（Cbal , Cleak）の算出ツール</span>
                    <span class="version">Ver.3.6.0 (2024.04)</span>
                </div>
            </h1>
          </router-link>
      </div>
  </header>
</template>


<script>
/**
 * 共通ヘッダ
 *
 * [プロパティ]
 *
 * [算出プロパティ]
 *
 * [イベント処理]
 *
 * [利用例]
 * <layout-header
 * />
 *
 * [データ]
 */

export default {
    name:"LayoutHeader",
    data(){
        return {
            foldIsShow: false
        }
    },
    components:{
    },
    props: {
    },
    computed:{
      isComfirmed(){
        if(this.$route.name !== "index"){
          return true
        } else {
          return false
        }
      },
    },
    methods: {
        /**
         * ハンバーガーメニューを表示切り替え
         */
        toggleMenu(){
            this.foldIsShow = !this.foldIsShow
        }
    }
}
</script>

<style scoped>
.ly_header{
    display: flex;
    flex-direction: column;
    min-width: 100%;
    height: 55px;
    background-color: #53BFD8;
    position: absolute;
}
.ly_header_inner{
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1000px;
    height: 55px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
}
.ly_header_inner h1{
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 8px 0;
    font-size: 22px;
    letter-spacing: 0.05em;
    font-weight: 500;
    color: white;
}
.bl_header_inner h1 .bl_header_inner_icon{
    content: "";
    display: inline-block;
    width: 60px;
    height: 45px;
    margin-top: 0;
    color: transparent;
    background-image: url(/img/all_img.png);
    background-repeat: no-repeat;
    background-size: auto 135px;
    background-position-x: -500px;
}
.ly_header_inner_title{
    display: inline-flex;
    align-items: center;
}
.ly_header_inner h1 span{
    display: inline-block;
    font-size: 18px;
    line-height: 18px;
    margin-top: 4px;
    margin-right: 8px;
    white-space: nowrap;
}
.ly_header_inner h1 span.version{
    display: inline-block;
    margin: 0;
    padding: 2px 12px;
    font-size: 11px;
    font-weight: bolder;
    color: #fff;
    background-color: #0443a8;
    border-radius: 3px;
    letter-spacing: 0;
}
.uq_button_container{
    display: flex;
    align-items: center;
    margin-left: auto;
}
.toresult{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height: 40px;
    margin-top: 35px;
    margin-right: 22px;
    margin-left: auto;
    position: relative;
    font-size: 14px;
    font-weight: bolder;
    color: white;
    white-space: nowrap;
    background: url(/img/all_img.png) no-repeat;
    background-size: auto 115px;
    background-position-x: 36.5%;
    background-position-y: 65%;
    cursor: pointer;
}
.toresult:disabled,
.toresult[disabled="true"]{
    opacity: 1;
    color: #ccc;
    background-position-x: 62.2%;
    cursor: default;
    pointer-events: none;
}
.btn_calc{
    display: flex;
    width: 80px;
    min-width: 80px;
    height: 80px;
    margin-bottom: -30px;
    margin-left: -15px;
    font-size: 18px;
    font-weight: bold;
    position:relative;
    color: transparent;
    background: url(/img/all_img.png) no-repeat; /* スプライト呼び出し */
    background-size: auto 100px;
    background-position-x: 85.0%;
    background-position-y: 47.5%;
    background-color: #0443A8;
    border-radius: 50%;
    cursor: pointer;
}
.white_box{
    display: none;
}
/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 1000px) {
    .ly_header{
        height: auto;
        z-index: 990;
        position: fixed;
        top: 0;
    }
    .ly_header_inner{
        display: flex;
        flex-direction: column;
        height: auto;
    }
    .uq_button_container{
        flex-direction: row-reverse;
        width: 100%;
        padding: 16px;
        background-color: white;
    }
    .uq_button_container .toresult{
        background: none;
        background-color: #aaa;
        width: 50%;
        height: 50px;
        margin: 0;
    }
    .uq_button_container .btn_calc{
        display: flex;
        align-items: center;
        justify-content: center;
        content: "計算する";
        background: none;
        color: white;
        background-color: #0443A8;
        width: 50%;
        max-width: 50%;
        height: 50px;
        margin: 0 16px 0 0;
        border-radius: 25px;
    }
    .ly_header_inner h1 .bl_header_inner_title{
        display: inline-flex;
        flex-wrap:wrap;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        line-height: 20px;
    }
    .bl_header_inner h1 .bl_header_inner_title span:first-child{
        display:inline-block;
        font-size: 12px;
        line-height: 12px;
        margin-right: 8px;
    }
    .white_box{
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        background-color: #555;
        border-top-right-radius: 0;
        height: 40px;
        padding: 0;
    }
    .white_box .btn_menu{
        display: inline-block;
        width: 100%;
        text-align: center;
    }
}
</style>